import React from 'react';

import { Avatar, Chip, Typography } from '@mui/material';

import UserCellTotalContent from '../ui-component/table-elements/UserCellTotalContent';

export function createData(employee) {
  const { fullName, avatarUrl, clockifyUserId } = employee;
  return {
    ...employee,
    fullName: (
      <>
        <Typography>{fullName}</Typography>
        {clockifyUserId === null ? <Chip label="Not Verified Clockify Account" color="error" /> : null}
      </>
    ),
    avatar: <Avatar alt={fullName} src={avatarUrl} />,
    duration: null,
    workingDates: [],
  };
}

export function methodOfEndpoint(method) {
  switch (method) {
    case 'post': {
      return 'POST';
    }
    case 'put': {
      return 'PUT';
    }
    case 'delete': {
      return 'DELETE';
    }
    default: {
      return 'GET';
    }
  }
}

export const setWorkingDate = ({ duration, date, durationValue, normativeTime, user, rangeOfDays = null }) => {
  const { id } = user;

  const notCountedTime = 0;

  if (rangeOfDays !== null) {
    rangeOfDays.map(({ formatted: day }) => {
      return day;
    });
  }

  const data = {
    amount: Number(duration), // per month
    duration: Number(durationValue), // per day
    normativeTime,
    notCountedTime,
    date,
    profit: Number(durationValue - normativeTime + notCountedTime),
    userId: Number(id),
  };
  return {
    data,
    component: <UserCellTotalContent data={data} />,
  };
};
